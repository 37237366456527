// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-smart-city-charts-js": () => import("./../../../src/pages/smart-city-charts.js" /* webpackChunkName: "component---src-pages-smart-city-charts-js" */),
  "component---src-pages-smart-city-js": () => import("./../../../src/pages/smart-city.js" /* webpackChunkName: "component---src-pages-smart-city-js" */),
  "component---src-pages-system-js": () => import("./../../../src/pages/system.js" /* webpackChunkName: "component---src-pages-system-js" */),
  "component---src-pages-vileo-js": () => import("./../../../src/pages/vileo.js" /* webpackChunkName: "component---src-pages-vileo-js" */)
}

