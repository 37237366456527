import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
    palette: {
        orange: '#FA7800',
        lightBlue: '#0072FF',
        blue: '#033BF6',
        darkBlue: '#140091',
    },
    typography: {
        fontFamily: 'Work Sans, sans-serif',
        h1: {
            fontSize: '96px',
            fontWeight: 'bold',
            lineHeight: '113px',
            letterSpacing: '0.08em',
            color: '#FFF',
            [breakpoints.down('lg')]: {
                fontSize: '76px',
                lineHeight: '84px',
            },
            [breakpoints.down('md')]: {
                fontSize: '60px',
                lineHeight: '76px',
            },
            [breakpoints.down('sm')]: {
                fontSize: '40px',
                lineHeight: '50px',
            },
            '@media (max-width: 400px)': {
                fontSize: '30px',
                lineHeight: '40px',
            },
            '@media (max-width: 300px)': {
                fontSize: '24px',
                lineHeight: '32px',
            },
        },
        subtitle2: {
            color: '#000',
            fontSize: '32px',
            lineHeight: '38px',
            letterSpacing: '0.01em',
            fontWeight: 415,
            [breakpoints.down('lg')]: {
                fontSize: '26px',
                lineHeight: '30px',
            },
            [breakpoints.down('md')]: {
                fontSize: '21px',
                lineHeight: '24px',
            },
            [breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '20px',
            },
            [breakpoints.down('xs')]: {
                fontSize: '12px',
                lineHeight: '14px',
            },
        },
        body1: {
            fontSize: '26px',
            lineHeight: '30px',
            letterSpacing: '0.02em',
            color: '#FFF',
            [breakpoints.down('lg')]: {
                fontSize: '21px',
                lineHeight: '24px',
            },
            [breakpoints.down('md')]: {
                fontSize: '18px',
                lineHeight: '21px',
            },
            [breakpoints.down('xs')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        body2: {
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.02em',
            color: '#FFF',
            [breakpoints.down('lg')]: {
                fontSize: '16px',
                lineHeight: '19px',
            },
            [breakpoints.down('md')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
            [breakpoints.down('xs')]: {
                fontSize: '12px',
                lineHeight: '14px',
            },
        },
    },
});

export default theme;
